import axios from "axios"
import { supabase } from "./supabaseClient"
import CryptoJS from "crypto-js";
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY
const secretKey = process.env.REACT_APP_SECRET_KEY

const apiClient = axios.create({
    timeout: 15000,
    headers: { "Content-Type": "application/json" },
})
apiClient.interceptors.request.use(async (config) => {
    const session = supabase.auth.getSession()
    if (session) {
        config.headers.Authorization = `Bearer ${session.access_token}`
    }
    return config
})

const useAxiosWrapper = () => {
    const api = async (options) => {
        try {
            let baseUrl = supabaseUrl
            if (options.baseUrl) {
                baseUrl = options.baseUrl
            }
            options.url = baseUrl + "functions/v1" + options.url
            console.log(options.url, " 호출!")

            const response = await apiClient({
                headers: { Authorization: `Bearer ${supabaseAnonKey}` },
                method: "GET",
                ...options,
            })
            console.log("response data : ", response.data)
            return response.data
        } catch (err) {
            console.log("error : ", err)
        }
    }

    return { api }
}

const renderImage = (path) => {
    return `${supabaseUrl}storage/v1/object/public/bloport/${path}`
}

const encrypt = (value) => {
    return CryptoJS.AES.encrypt(value.toString(), secretKey).toString()
}
const decrypt = (value) => {
    const bytes = CryptoJS.AES.decrypt(value, secretKey)
    return bytes.toString(CryptoJS.enc.Utf8)
}

export { useAxiosWrapper, renderImage, encrypt, decrypt }
