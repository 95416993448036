import React from "react"
import { Link } from "react-router-dom"

const Header = () => {
    const isLoggedIn = localStorage.getItem("isLoggedIn")

    return (
        <header className="flex justify-between items-center p-4 bg-white shadow-md">
            <Link to="/">
                <h1 className="text-2xl font-bold">BLOG</h1>
            </Link>
            <div className="flex items-center">
                {isLoggedIn && (
                    <>
                        <Link to="/createPost">
                            <button className="bg-green-500 text-white py-2 px-2 rounded mr-4 text-xs">
                                글 작성하기
                            </button>
                        </Link>
                        <Link to="/createPeed">
                            <button className="bg-blue-700 text-white py-2 px-4 rounded mr-4 text-xs">
                                피드 작성하기
                            </button>
                        </Link>
                    </>
                )}
                {!isLoggedIn && (
                    <Link to="/login">
                        <button className="bg-blue-500 text-white py-2 px-4 rounded">
                            로그인
                        </button>
                    </Link>
                )}
            </div>
        </header>
    )
}

export default Header
