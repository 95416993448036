import React, { useEffect } from "react"
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
    useLocation,
} from "react-router-dom"
import Home from "./components/Home"
import ContentDetail from "./components/ContentDetail"
import Login from "./components/Login"
import SignUp from "./components/SignUp"
import Board from "./components/Board"
import CreatePost from "./components/CreatePost"
import PostDetail from "./components/PostDetail"
import "./App.css"

function App() {
    return (
        <Router>
            <PageRouter />
        </Router>
    )
}

// 
function PageRouter() {
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const page = params.get("page")
        const id = params.get("id")

        if (page) {
            switch (page) {
                case "post":
                    navigate(`/post?id=${id}`)
                    break
                default:
                    navigate("/")
            }
        }
    }, [location, navigate])

    return (
        <div className="App w-full h-full full-height-element overflow-y-auto">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/board" element={<Board />} />
                <Route path="/contentDetail" element={<ContentDetail />} />
                <Route path="/createPost" element={<CreatePost />} />
                <Route path="/post" element={<PostDetail />} />
            </Routes>
        </div>
    )
}

export default App
