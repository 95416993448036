import React, { useEffect, useState } from "react"
import { useAxiosWrapper } from "../common"

const Board = () => {
    const [posts, setPosts] = useState([])
    const { api } = useAxiosWrapper()

    useEffect(() => {
        fetchPosts()
    }, [])

    const fetchPosts = async () => {
        const data = await api.get("/posts") // Replace with your API endpoint

        if (data) {
            setPosts(data)
        }
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Board</h1>
            <ul>
                {posts.map((post) => (
                    <li key={post.id} className="mb-4">
                        <h2 className="text-xl font-semibold">{post.title}</h2>
                        <p>{post.content}</p>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Board
