import { create } from "zustand"

const useStore = create((set) => ({
    latestPosts: [],
    setLatestPosts: (latestPosts) => set({latestPosts}),
    travelPlans: [],
    setTravelPlans: (travelPlans) => set({travelPlans}),
}))

export default useStore
