import React, { useEffect, useState } from "react"
import { useParams, useNavigate, useLocation } from "react-router-dom"
import { supabase } from "../supabaseClient"
import { renderImage, encrypt, decrypt } from "../common"
import { FaArrowLeft, FaShareAlt } from "react-icons/fa"

const PostDetail = () => {
    const navigate = useNavigate() // 리다이렉트를 위해 useNavigate 사용
    const location = useLocation() // 현재 위치를 가져옴
    // URL의 쿼리 스트링에서 id 값을 추출
    const params = new URLSearchParams(location.search)
    const id = params.get("id")
    const [post, setPost] = useState(null)
    const [travelData, setTravelData] = useState(null)
    const [isSharePopupOpen, setIsSharePopupOpen] = useState(false)

    let decryptedId
    // Link를 통해 넘어온 경우, 복호화하지 않음
    if (location.state && location.state.fromLink) {
        decryptedId = id
    } else {
        decryptedId = decrypt(id)
    }

    useEffect(() => {
        const fetchPost = async () => {
            try {
                // 복호화된 id가 숫자가 아닌 경우 메인으로 리다이렉트
                if (!decryptedId || isNaN(decryptedId)) {
                    alert("존재하지 않는 페이지 입니다.")
                    navigate("/", { replace: true }) // navigate에 replace 옵션을 추가
                    return
                } else {
                    // posts 테이블에서 포스트 데이터를 조회
                    const { data: post, error: postError } = await supabase
                        .from("posts")
                        .select("*")
                        .eq("id", decryptedId)
                        .single()

                    if (postError || !post) {
                        alert("존재하지 않는 페이지 입니다.")
                        navigate("/", { replace: true }) // navigate에 replace 옵션을 추가
                        return
                    }

                    setPost(post)

                    // type이 travel이라면 travel 테이블에서 데이터를 조회
                    if (post.type === "travel") {
                        const { data: travel, error: travelError } =
                            await supabase
                                .from("travel")
                                .select("*")
                                .eq("post_id", decryptedId)
                                .single()

                        if (travelError) {
                            throw travelError
                        }

                        setTravelData(travel)
                    }
                }
            } catch (error) {
                console.error("Error fetching post:", error)
            }
        }

        fetchPost()
    }, [id, navigate, location.state])

    const handleShareClick = () => {
        setIsSharePopupOpen(true)
    }

    const handleCopyLink = () => {
        const encryptedId = encrypt(decryptedId)
        const encodeId = encodeURIComponent(encryptedId)
        const shareLink = `${window.location.origin}?page=post&id=${encodeId}`
        navigator.clipboard.writeText(shareLink).then(() => {
            alert("링크가 복사되었습니다!")
            setIsSharePopupOpen(false)
        })
    }

    const handleBackClick = () => {
        // URL을 통해 진입한 경우 메인 화면으로 이동
        if (!location.state || !location.state.fromLink) {
            navigate("/", { replace: true })
        } else {
            navigate(-1) // 링크를 통해 진입한 경우, 이전 페이지로 이동
        }
    }

    if (!post) {
        return <p>Loading...</p>
    }

    return (
        <div className="container mx-auto p-4 md:w-1/3 relative">
            {/* 뒤로 가기 버튼 */}
            <button
                onClick={handleBackClick}
                className="absolute top-6 left-8 bg-white p-2 rounded-full shadow-md"
            >
                <FaArrowLeft />
            </button>

            {/* 공유하기 버튼 */}
            <button
                onClick={handleShareClick}
                className="absolute top-6 right-8 bg-white p-2 rounded-full shadow-md"
            >
                <FaShareAlt />
            </button>

            {/* 공유하기 팝업 */}
            {isSharePopupOpen && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-80 text-center">
                        <h3 className="text-lg font-bold mb-4">공유하기</h3>
                        <button
                            onClick={handleCopyLink}
                            className="bg-yellow-500 text-white px-4 py-2 rounded-full mb-4 w-full"
                        >
                            링크 복사
                        </button>
                        <button
                            onClick={() => setIsSharePopupOpen(false)}
                            className="text-gray-700 px-4 py-2 rounded-full w-full"
                        >
                            닫기
                        </button>
                    </div>
                </div>
            )}

            <div className="bg-white p-6 rounded-lg shadow-md pt-14">
                <h1 className="text-2xl font-bold mb-4">{post.title}</h1>
                <img
                    src={renderImage(post.thumbnail)}
                    alt={post.title}
                    className="w-full h-64 object-cover mb-4 rounded"
                />
                <p className="text-gray-500 mb-4">{post.summary}</p>
                <div className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">내용</h2>
                    <p className="text-gray-700">{post.content?.text}</p>
                </div>
                <hr className="my-6 border-t border-gray-200" />
                {post.type === "travel" && travelData && (
                    <div>
                        <div className="mb-6">
                            <h2 className="text-xl font-semibold mb-2">
                                여행 정보
                            </h2>
                            <p className="text-gray-700">
                                <span className="font-semibold">
                                    여행 기간:
                                </span>{" "}
                                {travelData.start_date} - {travelData.end_date}
                            </p>
                        </div>
                        <hr className="my-6 border-t border-gray-200" />
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-2">일정</h3>
                            {travelData.schedule?.map((scheduleItem, index) => (
                                <div key={index} className="mb-4">
                                    <p className="font-semibold text-orange-500">
                                        {scheduleItem.date}
                                    </p>
                                    {scheduleItem.details?.map(
                                        (detail, detailIndex) => (
                                            <div
                                                key={detailIndex}
                                                className="ml-4 flex items-center mb-2"
                                            >
                                                <span className="text-gray-700 mr-2">
                                                    <strong>
                                                        {detail.time}
                                                    </strong>{" "}
                                                    : {detail.action}
                                                </span>
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                        </div>
                        <hr className="my-6 border-t border-gray-200" />
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-2">정보</h3>
                            {Array.isArray(travelData.info) &&
                                travelData.info.map((infoItem, index) => (
                                    <div key={index} className="mb-4">
                                        <p className="text-gray-700">
                                            <strong>
                                                {infoItem.infoTitle}
                                            </strong>
                                            : {infoItem.infoText}
                                        </p>
                                    </div>
                                ))}
                        </div>
                        <hr className="my-6 border-t border-gray-200" />
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-2">
                                지출 내역
                            </h3>
                            {travelData.bill?.map((billItem, index) => (
                                <div key={index} className="mb-4">
                                    <p className="text-gray-700">
                                        <strong>{billItem.place}</strong> :
                                        {billItem.amount} 원
                                    </p>
                                </div>
                            ))}
                        </div>
                        <hr className="my-6 border-t border-gray-200" />
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-2">
                                활동 노트
                            </h3>
                            {travelData.activityNote?.map((noteItem, index) => (
                                <div key={index} className="mb-4">
                                    {noteItem.image && (
                                        <img
                                            src={noteItem.image}
                                            alt="Activity"
                                            className="mb-2 w-full h-40 object-cover rounded-lg"
                                        />
                                    )}
                                    {noteItem.link && (
                                        <a
                                            href={noteItem.link}
                                            className="text-blue-500 underline mb-2 block"
                                        >
                                            {noteItem.link}
                                        </a>
                                    )}
                                    <p className="text-gray-600">
                                        {noteItem.text}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PostDetail
