import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../supabaseClient"
import Header from "./Header"

const Login = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const navigate = useNavigate()

    const handleLogin = async () => {
        const { error } = await supabase.auth.signInWithPassword({
            email,
            password,
        })

        if (error) {
            alert("로그인 실패: " + error.message)
        } else {
            localStorage.setItem("isLoggedIn", "true")
            navigate("/")
        }
    }

    return (
        <div className="relative min-h-screen bg-gray-100">
            <Header />
            <div className="flex justify-center items-center h-screen bg-gray-100">
                <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
                    <h2 className="text-2xl font-bold mb-6">로그인</h2>
                    <input
                        type="email"
                        placeholder="이메일"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded mb-4"
                    />
                    <input
                        type="password"
                        placeholder="비밀번호"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded mb-4"
                    />
                    <button
                        onClick={handleLogin}
                        className="w-full bg-blue-500 text-white p-2 rounded mb-4"
                    >
                        로그인
                    </button>
                    {/* <div className="text-center">
                        <p className="text-gray-600">계정이 없으신가요?</p>
                        <Link to="/signup">
                            <button className="w-full bg-green-500 text-white p-2 rounded mt-2">
                                회원가입
                            </button>
                        </Link>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Login
