import React, { useEffect, useState } from "react"
import "./Home.css"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import Header from "./Header"
import { useAxiosWrapper, renderImage } from "../common"
import { Link } from "react-router-dom"
import useStore from "../commonStore"
import FeedTimeline from "./FeedTimeline" // FeedTimeline 컴포넌트 추가

const Home = () => {
    const { api } = useAxiosWrapper()
    const { latestPosts, setLatestPosts, travelPlans, setTravelPlans } =
        useStore()

    const fetchPosts = async () => {
        const data = await api({
            method: "POST",
            url: "/post",
            data: { action: "getMainPost" },
        })

        if (data) {
            setLatestPosts(data.latestPosts || [])
            setTravelPlans(data.travelPlans || [])
        }
    }

    useEffect(() => {
        fetchPosts()
    }, [])

    return (
        <div className="relative min-h-screen bg-gray-100">
            <Header />

            {/* 최신 게시물 캐러셀 */}
            {latestPosts.length > 0 && (
                <div className="flex flex-col items-center justify-center mt-8">
                    {/* 피드 타임라인 추가 */}
                    <FeedTimeline />

                    <h2 className="text-3xl font-bold mb-6 mt-16">
                        최신 게시물
                    </h2>
                    <div className="w-full md:w-2/5">
                        <Carousel
                            showArrows={true}
                            showThumbs={false}
                            infiniteLoop={true}
                            autoPlay={true}
                            interval={5000}
                            showIndicators={false}
                            stopOnHover={true}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <button
                                        type="button"
                                        onClick={onClickHandler}
                                        title={label}
                                        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-black text-white p-2 rounded-full z-10"
                                        style={{ opacity: 0.8 }}
                                    >
                                        &#10094;
                                    </button>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <button
                                        type="button"
                                        onClick={onClickHandler}
                                        title={label}
                                        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-black text-white p-2 rounded-full z-10"
                                        style={{ opacity: 0.8 }}
                                    >
                                        &#10095;
                                    </button>
                                )
                            }
                        >
                            {latestPosts.map((post) => (
                                <Link
                                    to={`/post?id=${post.id}`}
                                    state={{ fromLink: true }}
                                >
                                    <div key={post.id} className="p-4">
                                        <img
                                            src={renderImage(post.thumbnail)}
                                            alt={post.title}
                                            className="w-full h-64 object-cover mb-4 rounded"
                                        />
                                        <h3 className="text-xl font-bold mb-2">
                                            {post.title}
                                        </h3>
                                        <p className="text-gray-700">
                                            {post.summary}
                                        </p>
                                    </div>
                                </Link>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}

            {/* 여행 일정 캐러셀 */}
            {travelPlans.length > 0 && (
                <div className="flex flex-col items-center justify-center mt-16">
                    <h2 className="text-3xl font-bold mb-6">여행 일정</h2>
                    <div className="w-full md:w-2/5">
                        <Carousel
                            showArrows={true}
                            showThumbs={false}
                            infiniteLoop={true}
                            autoPlay={true}
                            interval={5000}
                            showIndicators={false}
                            stopOnHover={true}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <button
                                        type="button"
                                        onClick={onClickHandler}
                                        title={label}
                                        className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-black text-white p-2 rounded-full z-10"
                                        style={{ opacity: 0.8 }}
                                    >
                                        &#10094;
                                    </button>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <button
                                        type="button"
                                        onClick={onClickHandler}
                                        title={label}
                                        className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-black text-white p-2 rounded-full z-10"
                                        style={{ opacity: 0.8 }}
                                    >
                                        &#10095;
                                    </button>
                                )
                            }
                        >
                            {travelPlans.map((plan) => (
                                <Link
                                    to={`/post?id=${plan.id}`}
                                    state={{ fromLink: true }}
                                >
                                    <div key={plan.id} className="p-4">
                                        <img
                                            src={renderImage(plan.thumbnail)}
                                            alt={plan.title}
                                            className="w-full h-64 object-cover mb-4 rounded"
                                        />
                                        <h3 className="text-xl font-bold mb-2">
                                            {plan.title}
                                        </h3>
                                        <p className="text-gray-700">
                                            {plan.summary}
                                        </p>
                                    </div>
                                </Link>
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Home
