import React, { useState } from "react"
import { useAxiosWrapper } from "../common"
import Header from "./Header"

const CreatePost = () => {
    const { api } = useAxiosWrapper()
    const [title, setTitle] = useState("")
    const [summary, setSummary] = useState("")
    const [type, setType] = useState("normal")
    const [content, setContent] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [schedule, setSchedule] = useState([])
    const [info, setInfo] = useState([])
    const [bill, setBill] = useState([])
    const [activityNote, setActivityNote] = useState([])

    const handleAddSchedule = () => {
        setSchedule([...schedule, { date: "", time: "", action: "" }])
    }

    const handleAddInfo = () => {
        setInfo([...info, { infoTitle: "", infoText: "" }])
    }

    const handleAddBill = () => {
        setBill([...bill, { place: "", amount: "" }])
    }

    const handleAddActivityNote = () => {
        setActivityNote([...activityNote, { image: "", link: "", text: "" }])
    }

    const handleSubmit = async () => {
        const data = await api({
            method: "POST",
            url: "/post",
            headers: { "Content-Type": "application/json" },
            data: {
                action: "create",
                title,
                summary,
                type,
                content,
                startDate,
                endDate,
                schedule,
                info,
                bill,
                activityNote,
            },
        })

        if (data && !data.error) {
            alert("Post created successfully!")
        } else {
            alert("Error creating post: " + (data.error || "Unknown error"))
        }
    }

    return (
        <div className="relative min-h-screen bg-gray-100">
            <Header />
            <div className="container mx-auto p-4">
                <h2 className="text-2xl font-bold mb-6">글 작성하기</h2>
                <input
                    type="text"
                    placeholder="제목"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                />
                <textarea
                    placeholder="요약"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                />
                <select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                >
                    <option value="normal">Normal</option>
                    <option value="travel">Travel</option>
                </select>
                <textarea
                    placeholder="내용"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded mb-4"
                />

                {type === "travel" && (
                    <>
                        <h3 className="text-xl font-bold mb-2">여행 정보</h3>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded mb-4"
                        />
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded mb-4"
                        />
                        <h3 className="text-xl font-bold mb-2">일정 추가</h3>
                        {schedule.map((_, index) => (
                            <div key={index} className="mb-4">
                                <input
                                    type="date"
                                    placeholder="일정 날짜"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setSchedule(
                                            schedule.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          date: e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="시간"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setSchedule(
                                            schedule.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          time: e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="행동"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setSchedule(
                                            schedule.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          action: e.target
                                                              .value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                            </div>
                        ))}
                        <button
                            onClick={handleAddSchedule}
                            className="bg-green-500 text-white p-2 rounded mb-4"
                        >
                            일정 추가
                        </button>

                        <h3 className="text-xl font-bold mb-2">정보 추가</h3>
                        {info.map((_, index) => (
                            <div key={index} className="mb-4">
                                <input
                                    type="text"
                                    placeholder="안내 타이틀"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setInfo(
                                            info.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          infoTitle:
                                                              e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="안내 내용"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setInfo(
                                            info.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          infoText:
                                                              e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                            </div>
                        ))}
                        <button
                            onClick={handleAddInfo}
                            className="bg-green-500 text-white p-2 rounded mb-4"
                        >
                            정보 추가
                        </button>

                        <h3 className="text-xl font-bold mb-2">
                            지출 내역 추가
                        </h3>
                        {bill.map((_, index) => (
                            <div key={index} className="mb-4">
                                <input
                                    type="text"
                                    placeholder="사용처"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setBill(
                                            bill.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          place: e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                                <input
                                    type="number"
                                    placeholder="지출금액"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setBill(
                                            bill.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          amount: e.target
                                                              .value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                            </div>
                        ))}
                        <button
                            onClick={handleAddBill}
                            className="bg-green-500 text-white p-2 rounded mb-4"
                        >
                            지출 내역 추가
                        </button>

                        <h3 className="text-xl font-bold mb-2">
                            활동 노트 추가
                        </h3>
                        {activityNote.map((_, index) => (
                            <div key={index} className="mb-4">
                                <input
                                    type="text"
                                    placeholder="이미지 링크"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setActivityNote(
                                            activityNote.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          image: e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="관련 링크"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setActivityNote(
                                            activityNote.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          link: e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                                <textarea
                                    placeholder="메모"
                                    className="w-full p-2 border border-gray-300 rounded mb-2"
                                    onChange={(e) =>
                                        setActivityNote(
                                            activityNote.map((item, i) =>
                                                i === index
                                                    ? {
                                                          ...item,
                                                          text: e.target.value,
                                                      }
                                                    : item
                                            )
                                        )
                                    }
                                />
                            </div>
                        ))}
                        <button
                            onClick={handleAddActivityNote}
                            className="bg-green-500 text-white p-2 rounded mb-4"
                        >
                            활동 노트 추가
                        </button>
                    </>
                )}

                <button
                    onClick={handleSubmit}
                    className="w-full bg-blue-500 text-white p-2 rounded mb-4"
                >
                    게시글 등록
                </button>
            </div>
        </div>
    )
}

export default CreatePost
