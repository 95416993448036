import React, { useEffect } from "react"

function ContentDetail() {
    useEffect(() => {
        const script = document.createElement("script")
        script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=k9S64uL0KRx0fhepE1II`
        script.async = true
        script.onload = () => {
            const map = new window.naver.maps.Map("map", {
                center: new window.naver.maps.LatLng(37.734581, 127.492203),
                zoom: 15,
            })

            new window.naver.maps.Marker({
                position: new window.naver.maps.LatLng(37.734581, 127.492203),
                map: map,
            })
        }
        document.head.appendChild(script)
    }, [])

    return (
        <div className="p-6 bg-gray-100 min-h-screen flex justify-center items-start">
            <div className="max-w-3xl w-full bg-white p-8 rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold mb-4">
                    똥주와 함께하는 대환장파티
                </h1>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">날짜</h2>
                    <p>2024/08/30 ~ 2024/09/01</p>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">일정</h2>
                    <ul className="list-disc list-inside">
                        <li>
                            <strong>8/30</strong>
                            <ul className="list-disc list-inside ml-6">
                                <li>18:00 퇴근</li>
                                <li>코스트코 장보기</li>
                                <li>미리 손질</li>
                                <li>반주</li>
                                <li>취침</li>
                                <li>완벽</li>
                            </ul>
                        </li>
                        <li>
                            <strong>8/31</strong>
                            <ul className="list-disc list-inside ml-6">
                                <li>각자 본인이 알아서 점심</li>
                                <li>1시 30분 집합 및 출발</li>
                                <li>2시 30분 ~ 3시 도착</li>
                                <li>3시 30분 짐정리</li>
                                <li>통삼겹 훈제시작</li>
                                <li>게임시작 3시 30분~</li>
                                <li>6시 30분 ~ 7시 저녁식사 시작</li>
                                <li>자유시간</li>
                            </ul>
                        </li>
                        <li>
                            <strong>9/1</strong>
                            <ul className="list-disc list-inside ml-6">
                                <li>기상</li>
                                <li>해장</li>
                                <li>뒷정리</li>
                                <li>점심식사</li>
                                <li>해산</li>
                            </ul>
                        </li>
                    </ul>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">차량 편성</h2>
                    <div className="space-y-4">
                        <div>
                            <h3 className="font-semibold">1번</h3>
                            <ul className="list-disc list-inside ml-6">
                                <li>1호: 동주현, 김성신, 허강희, 빈민혁</li>
                                <li>2호: 이준영, 이은슬, 김성길</li>
                                <li>3호(의정부출발): 이형우, 이수민</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-semibold">2번</h3>
                            <ul className="list-disc list-inside ml-6">
                                <li>1호: 동주현, 김성신, 이은슬, 이지우?</li>
                                <li>2호: 이준영, 김성실, 허강희, 빈민혁</li>
                                <li>3호(의정부출발): 이형우, 이수민</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-semibold">3번</h3>
                            <ul className="list-disc list-inside ml-6">
                                <li>1호: 동주현, 김성신, 김성길</li>
                                <li>2호: 이준영, 이은슬, 허강희, 빈민혁</li>
                                <li>
                                    3호(의정부출발): 이형우, 이수민, 이은슬,
                                    이지우?
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">숙소 정보</h2>
                    <p>주소: 경기도 가평군 설악면 이천리380-1</p>
                    <p>이용 시간: 3시 입실, 11시 퇴실</p>
                    <p>
                        <a
                            href="https://www.airbnb.co.kr/rooms/1185332699471456923?check_in=2024-08-31&check_out=2024-09-01&guests=1&adults=10&s=67&unique_share_id=124958ae-ad94-446b-b9bb-534ccff46b35"
                            className="text-blue-500 underline"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            숙소 링크
                        </a>
                    </p>
                    <div id="map" style={{ width: '100%', height: '400px' }}></div>
                </section>

                <section className="mb-6">
                    <h2 className="text-xl font-semibold mb-2">안내사항</h2>
                    <div className="space-y-4">
                        <div>
                            <h3 className="font-semibold">비용 관련</h3>
                            <ul className="list-disc list-inside ml-6">
                                <li>운전자 차량 유류비 및 톨비 지원 5만원</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="font-semibold">준비물</h3>
                            <ul className="list-disc list-inside ml-6">
                                <li>
                                    주최자
                                    <ul className="list-disc list-inside ml-6">
                                        <li>명찰</li>
                                        <li>각종 게임도구</li>
                                        <li>정신머리</li>
                                        <li>책임감</li>
                                        <li>돈</li>
                                    </ul>
                                </li>
                                <li>
                                    인원공통(필요한사람만)
                                    <ul className="list-disc list-inside ml-6">
                                        <li>드라이기, 고데기</li>
                                        <li>개인세안용품</li>
                                        <li>
                                            수건( 숙소 인당 2장 필요한사람 더
                                            챙기기)
                                        </li>
                                        <li>흰옷(상의)</li>
                                        <li>검정옷(상의)</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ContentDetail
