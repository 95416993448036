import React, { useEffect, useState } from "react"
import { useAxiosWrapper } from "../common"

const FeedTimeline = () => {
    // const { api } = useAxiosWrapper()
    // const [feeds, setFeeds] = useState([])
    // useEffect(() => {
    //     const fetchFeeds = async () => {
    //         const data = await api({
    //             method: "GET",
    //             url: "/fetch-feeds", // API 엔드포인트 예시
    //         })

    //         if (data) {
    //             setFeeds(data.feeds || [])
    //         }
    //     }

    //     fetchFeeds()
    // }, [])

    // 목데이터 설정
    const [feeds] = useState([
        {
            timestamp: "2024-08-13 14:30:00",
            content: "첫 번째 피드 내용입니다.",
        },
        {
            timestamp: "2024-08-13 14:20:00",
            content: "두 번째 피드 내용은 조금 더 길어질 수 있습니다.",
        },
        {
            timestamp: "2024-08-13 14:10:00",
            content:
                "세 번째 피드는 정말로 길어서 줄임표 처리가 되어야 하는 내용입니다. 이 내용이 길어서 말 줄임표가 생길 것입니다.",
        },
        {
            timestamp: "2024-08-13 14:00:00",
            content: "네 번째 피드 내용입니다.",
        },
        {
            timestamp: "2024-08-13 13:50:00",
            content: "다섯 번째 피드 내용입니다.",
        },
        {
            timestamp: "2024-08-13 13:40:00",
            content: "여섯 번째 피드 내용입니다.",
        },
    ])

    const [showAll, setShowAll] = useState(false)
    const [selectedFeed, setSelectedFeed] = useState(null)

    const displayedFeeds = showAll ? feeds : feeds.slice(0, 5)

    return (
        <div className="p-4 bg-gray-100 w-full md:w-2/5">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-3xl font-bold">피드 타임라인</h2>
                {feeds.length > 5 && (
                    <button
                        onClick={() => setShowAll(!showAll)}
                        className="text-sm text-blue-500 hover:underline"
                    >
                        {showAll ? "접기" : "더보기"}
                    </button>
                )}
            </div>
            <div className="relative flex flex-col gap-4 before:absolute before:left-5 before:top-0 before:bottom-0 before:w-px before:bg-gray-300">
                {displayedFeeds.map((feed, index) => (
                    <div
                        key={index}
                        className="relative flex items-center pl-8 cursor-pointer"
                        onClick={() => setSelectedFeed(feed)}
                    >
                        <div className="w-3 h-3 bg-blue-500 rounded-full absolute left-3.5 top-1/2 transform -translate-y-1/2"></div>
                        <div className="bg-white p-4 rounded-lg shadow-md w-full">
                            <p className="text-sm text-gray-600 truncate">
                                {feed.timestamp} : {feed.content}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            {selectedFeed && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                        <h3 className="text-lg font-bold mb-4">
                            {selectedFeed.timestamp}
                        </h3>
                        <p className="text-gray-700">{selectedFeed.content}</p>
                        <button
                            onClick={() => setSelectedFeed(null)}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-full"
                        >
                            닫기
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FeedTimeline
